<template>
  <div>
    <div class="d-flex mt-6">
      <v-subheader class="align-start"> 登录页背景图</v-subheader>
      <v-img
        ref="logoImg"
        contain
        class="mx-6"
        max-height="100"
        max-width="100"
        :src="loginUrl ? loginUrl : `${baseUrl}/upload/${endLoginUrl}`"
        lazy-src="../../../assets/beforeUpload.jpg"
      ></v-img>
      <v-file-input
        class="upload"
        hide-input
        accept=".jpg"
        label="File input"
        @change="uploadLoginBg"
      ></v-file-input>
    </div>

    <div class="d-flex mt-6">
      <v-subheader class="mr-3 align-start"> 登录页LOGO</v-subheader>
      <v-img
        contain
        class="mx-6"
        max-height="100"
        max-width="100"
        :src="logoUrl ? logoUrl : `${baseUrl}/upload/${endLogoUrl}`"
        lazy-src="../../../assets/beforeUpload.png"
      ></v-img>
      <v-file-input
        class="upload"
        hide-input
        accept=".png"
        label="File input"
        @change="uploadLogo"
      ></v-file-input>
    </div>
    <v-form class="mt-4" :model="form" v-model="valid" ref="form">
      <v-row align="center" style="border-bottom:none !important;">
        <v-col cols="1">
          <v-subheader> 登录页标题</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field rows="1" v-model="logoDesc"></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader class="align-center"> 版权所有</v-subheader>
        </v-col>

        <v-col cols="4">
          <v-text-field
            rows="1"
            v-model="footContent"
            required
            persistent-hint
            hint="登录页中的版权所有提示"
            placeholder="版权所有 XX有限公司 2021"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="start" class="mt-4">
        <v-col cols="1">
          <v-subheader> 登录页样式</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="loginFontColor"
            hide-details
            class="ma-0 pa-0"
            solo
          >
            <template v-slot:append>
              <v-menu
                v-model="loginFontMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="loginFontStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="loginFontColor" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <span class="hint-style">登录页中字体颜色</span>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="loginHighLight"
            hide-details
            class="ma-0 pa-0"
            solo
          >
            <template v-slot:append>
              <v-menu
                v-model="HighLightMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="loginHighLightStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="loginHighLight" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <span class="hint-style">登录页中高亮颜色</span>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>自定义css</v-subheader>
        </v-col>

        <v-col cols="6">
          <v-textarea
            rows="1"
            auto-grow
            v-model="loginStyle"
            persistent-hint
            hint="登录页自定义样式"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-0 mt-8">
        <v-subheader style="font-size:18px">登录框</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>

      <!-- 输入框登录方式 -->
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 登录方式</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="firstLogin"
            :items="firstLogins"
            item-text="name"
            item-value="external_id"
            required
            :rules="
              (firstLogin != null && firstLogin !== '不启用') ||
              (qrcode != null && qrcode !== '不启用') ||
              (firstLink != null && firstLink !== '不启用')
                ? []
                : rules.hasLogin
            "
            @change="firstLoginSel(firstLogin)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="secondLogin"
            :items="secondLogins"
            item-text="name"
            item-value="external_id"
            :disabled="firstLogin == null || firstLogin === '不启用'"
            @change="secondLoginSel(secondLogin)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="thirdLogin"
            :items="thirdLogins"
            item-text="name"
            item-value="external_id"
            :disabled="
              firstLogin == null ||
                firstLogin === '不启用' ||
                secondLogin == null ||
                secondLogin === '不启用'
            "
            @change="thirdLoginSel(thirdLogin)"
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- 扫码登录方式 -->
      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 扫码登录方式</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="qrcode"
            :items="qrcodes"
            item-text="name"
            item-value="external_id"
            required
            :rules="
              (firstLogin != null && firstLogin !== '不启用') ||
              (qrcode != null && qrcode !== '不启用') ||
              (firstLink != null && firstLink !== '不启用')
                ? []
                : rules.hasLogin
            "
            @change="qrcodeSel(qrcode)"
          >
          </v-select>
        </v-col>

        <v-col cols="2">
          <v-checkbox
            v-model="showDefaultQrcode"
            @change="showDefaultQrcodeSel(showDefaultQrcode)"
            label="默认展示扫码登录"
            :disabled="qrcode === '不启用'"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- 其他登录方式 -->
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 其他登录方式</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="firstLink"
            :items="firstLinks"
            item-text="name"
            item-value="external_id"
            required
            :rules="
              (firstLogin != null && firstLogin !== '不启用') ||
              (qrcode != null && qrcode !== '不启用') ||
              (firstLink != null && firstLink !== '不启用')
                ? []
                : rules.hasLogin
            "
            @change="firstLinkSel(firstLink)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="secondLink"
            :items="secondLinks"
            item-text="name"
            item-value="external_id"
            :disabled="firstLink == null || firstLink === '不启用'"
            @change="secondLinkSel(secondLink)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="thirdLink"
            :items="thirdLinks"
            item-text="name"
            item-value="external_id"
            :disabled="
              firstLink == null ||
                firstLink === '不启用' ||
                secondLink == null ||
                secondLink === '不启用'
            "
            @change="thirdLinkSel(thirdLink)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="forthLink"
            :items="forthLinks"
            item-text="name"
            item-value="external_id"
            :disabled="
              firstLink == null ||
                firstLink === '不启用' ||
                secondLink == null ||
                secondLink === '不启用' ||
                thirdLink == null ||
                thirdLink === '不启用'
            "
            @change="forthLinkSel(forthLink)"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>
            输入框提示
          </v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="usernamePlaceholder"
            required
            persistent-hint
            hint="用户名文案"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="passwordPlaceholder"
            required
            persistent-hint
            hint="密码文案"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="verifycodePlaceholder"
            required
            persistent-hint
            hint="验证码文案"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 登录按钮</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="loginSubmit"
            required
            persistent-hint
            hint="登录按钮文案"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 自定义样式</v-subheader>
        </v-col>

        <v-col cols="3" class="mt-4">
          <v-slider
            class="slider-item"
            v-model="loginBgOpcity"
            @change="moveOpcity(loginBgOpcity)"
            label="登录框的透明度"
            persistent-hint
            hint="登录框的透明度范围（0~1），值越小透明度越明显"
            thumb-color="red"
            thumb-label
            min="0"
            max="1"
            step="0.01"
          ></v-slider>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="loginBgColor"
            hide-details
            class="ma-0 pa-0"
            solo
          >
            <template v-slot:append>
              <v-menu
                v-model="loginBgMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="loginBgStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="loginBgColor" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <span class="hint-style">登录框的背景颜色</span>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-0 mt-8">
        <v-subheader style="font-size:18px">温馨提示</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 温馨提示</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            v-model="showUseInstruction"
            @change="warmNoticeSel(showUseInstruction)"
            label="启用温馨提示"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row align="center" v-if="showUseInstruction">
        <v-col cols="1">
          <v-subheader> 温馨提示标题</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="useInstructionTitle"
            required
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" v-if="showUseInstruction">
        <v-col cols="1">
          <v-subheader> 温馨提示内容</v-subheader>
        </v-col>

        <v-col cols="6">
          <v-textarea
            rows="1"
            auto-grow
            v-model="useInstruction"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-0 mt-8">
        <v-subheader style="font-size:18px">页面布局</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>自定义布局</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="loginSite"
            :items="loginSites"
            item-text="name"
            item-value="site"
            @change="loginSiteSel(loginSite)"
            persistent-hint
            hint="登录框位置"
          >
          </v-select>
        </v-col>

        <v-col cols="2" v-if="loginSite !== 'center'">
          <v-select
            v-model="noticeDisplay"
            :items="noticeDisplays"
            item-text="name"
            item-value="id"
            @change="noticeDisplaySel(noticeDisplay)"
            persistent-hint
            hint="温馨提示内容展示方式"
          >
          </v-select>
        </v-col>

        <v-col cols="2" v-if="loginSite === 'center'">
          <v-select
            v-model="centerDisplay"
            :items="centerDisplays"
            item-text="name"
            item-value="id"
            persistent-hint
            hint="温馨提示内容展示方式"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "SetLogin",
  props: ["device_type", "customData"],
  data() {
    let isHasLogin = () => {
      if (
        this.firstLogin !== "不启用" ||
        this.qrcode !== "不启用" ||
        this.firstLink !== "不启用"
      ) {
        return true;
      } else {
        return false;
      }
    };

    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        hasLogin: [
          v => isHasLogin(v) || "登录方式和扫码登录方式中至少选择一种登录模式"
        ]
      },
      loginUrl: null,
      logoUrl: null,
      endLoginUrl: null,
      endLogoUrl: null,
      tab: null,
      isShow: false,
      loginBgUpload: {
        isShow: false,
        type: "success"
      },
      logoUpload: {
        isShow: false,
        type: "error"
      },
      loginBg: "#eee",
      top: "",
      right: "",
      footContent: "",
      logoDesc: "",
      useInstructionTitle: "",
      useInstruction: "",
      loginSubmit: "",
      usernamePlaceholder: "",
      passwordPlaceholder: "",
      verifycodePlaceholder: "",
      baseUrl: null,
      qrcode: null,
      qrcodes: [],
      showUseInstruction: false,
      allSources: [],
      allMethods: [],
      linkMethods: [],
      allLoginMehods: [],
      firstLogin: "不启用",
      secondLogin: "不启用",
      thirdLogin: "不启用",
      firstLogins: ["不启用"],
      secondLogins: ["不启用"],
      thirdLogins: ["不启用"],
      firstLink: "不启用",
      secondLink: "不启用",
      thirdLink: "不启用",
      forthLink: "不启用",
      firstLinks: ["不启用"],
      secondLinks: ["不启用"],
      thirdLinks: ["不启用"],
      forthLinks: ["不启用"],
      loginBgColor: "#0F0F0F",
      loginBgMenu: false,
      loginBgOpcity: 0.6,
      loginFontColor: "#F1F3F5",
      loginFontMenu: false,
      loginHighLight: "#F30707",
      HighLightMenu: false,
      loginSite: "right",
      loginSites: [
        { site: "right", name: "右置" },
        { site: "center", name: "居中" },
        { site: "left", name: "左置" }
      ],
      noticeDisplay: "dynamic",
      noticeDisplays: [
        { id: "dynamic", name: "可控展示" },
        { id: "staticState", name: "静态展示" }
      ],
      centerDisplay: "dynamic",
      centerDisplays: [{ id: "dynamic", name: "可控展示" }],
      loginStyle: "",
      showDefaultQrcode: false
    };
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    this.endLoginUrl = `custom_login/${this.$route.params.id}/${this.device_type}/login_bg.jpg`;
    this.endLogoUrl = `custom_login/${this.$route.params.id}/${this.device_type}/logo.png`;
    await this.getPreLoginStyle();
    await this.fetchSource();
    await this.fetchMethods();
    await this.dealQrcode();
    await this.dealLoginMehods();
    await this.dealLinkMehods();
  },
  methods: {
    // 默认展示扫码登录
    showDefaultQrcodeSel(showDefaultQrcode) {
      this.showDefaultQrcode = showDefaultQrcode;
    },
    loginSiteSel(loginSite) {
      this.loginSite = loginSite;
    },
    noticeDisplaySel(noticeDisplay) {
      this.noticeDisplay = noticeDisplay;
    },
    // 输入框登录方式
    firstLoginSel(firstLogin) {
      if (firstLogin != null || firstLogin !== "不启用") {
        this.firstLogin = firstLogin;
        this.secondLogin = this.secondLogins[0];
        this.thirdLogin = this.thirdLogins[0];

        this.secondLogins = this.firstLogins.filter(
          item => item.external_id !== firstLogin
        );
      } else {
        this.firstLogin = "不启用";
        this.secondLogin = "不启用";
        this.secondLogin = "不启用";
      }
    },
    secondLoginSel(secondLogin) {
      if (secondLogin != null || secondLogin !== "不启用") {
        this.secondLogin = secondLogin;
        this.thirdLogin = this.thirdLogins[0];
        this.secondLogins = this.firstLogins.filter(
          item => item.external_id !== this.firstLogin
        );

        this.thirdLogins = this.secondLogins.filter(
          item => item.external_id !== secondLogin
        );
      } else {
        this.secondLogin = "不启用";
        this.thirdLogin = "不启用";
      }
    },
    thirdLoginSel(thirdLogin) {
      if (thirdLogin != null || thirdLogin !== "不启用") {
        this.thirdLogin = thirdLogin;
        this.thirdLogins = this.secondLogins.filter(
          item => item.external_id !== this.secondLogin
        );
      } else {
        this.thirdLogin = "不启用";
      }
    },
    // 扫码登录方式
    qrcodeSel(qrcode) {
      this.qrcode = qrcode;
    },
    // 其他登录方式
    firstLinkSel(firstLink) {
      if (firstLink != null || firstLink !== "不启用") {
        this.firstLink = firstLink;
        this.secondLink = this.secondLinks[0];
        this.thirdLink = this.thirdLinks[0];
        this.forthLink = this.forthLinks[0];
        this.secondLinks = this.firstLinks.filter(
          item => item.external_id !== firstLink
        );
      } else {
        this.firstLink === "不启用";
        this.secondLink === "不启用";
        this.thirdLink === "不启用";
        this.forthLink === "不启用";
      }
    },
    secondLinkSel(secondLink) {
      if (secondLink != null || secondLink !== "不启用") {
        this.secondLink = secondLink;
        this.thirdLink = this.thirdLinks[0];
        this.secondLinks = this.secondLinks.filter(
          item => item.external_id !== this.firstLink
        );

        this.thirdLinks = this.secondLinks.filter(
          item => item.external_id !== secondLink
        );
      } else {
        this.secondLink === "不启用";
        this.thirdLink === "不启用";
        this.forthLink === "不启用";
      }
    },
    thirdLinkSel(thirdLink) {
      if (thirdLink != null || thirdLink !== "不启用") {
        this.thirdLink = thirdLink;
        this.forthLink = this.forthLinks[0];
        this.thirdLinks = this.thirdLinks.filter(
          item => item.external_id !== this.secondLink
        );

        this.forthLinks = this.thirdLinks.filter(
          item => item.external_id !== thirdLink
        );
      } else {
        this.thirdLink === "不启用";
        this.forthLink === "不启用";
      }
    },
    forthLinkSel(forthLink) {
      if (forthLink != null || forthLink !== "不启用") {
        this.forthLink = forthLink;
        this.forthLinks = this.thirdLinks.filter(
          item => item.external_id !== this.thirdLink
        );
      } else {
        forthLink === "不启用";
      }
    },
    changeLoginBgColor(loginBgColor) {
      this.loginBgColor = loginBgColor.substr(0, 7);
    },
    changeLoginFontColor(loginFontColor) {
      this.loginFontColor = loginFontColor.substr(0, 7);
    },
    changeLoginHighLight(loginHighLight) {
      this.loginHighLight = loginHighLight.substr(0, 7);
    },
    moveOpcity(loginBgOpcity) {
      this.loginBgOpcity = loginBgOpcity;
    },
    warmNoticeSel(showUseInstruction) {
      this.showUseInstruction = showUseInstruction;
    },
    uploadLoginBg(value) {
      let formData = new FormData();
      formData.append("file", value);
      formData.append("filename", "login_bg.jpg");
      formData.append("device_type", this.device_type);

      let reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.loginUrl = reader.result;
      };

      return this.$http
        .post(`api/custom_login/${this.$route.params.id}/upload`, formData)
        .delegateTo(api_request)
        .then(() => {
          this.$emit("sonVal", true);
          return this.$snackbar.showMessage({
            content: `上传成功`,
            isError: false
          });
        })
        .catch(({ code, message }) => {
          throw `上传失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    uploadLogo(value) {
      let formData = new FormData();
      formData.append("file", value);
      formData.append("filename", "logo.png");
      formData.append("device_type", this.device_type);

      let reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.logoUrl = reader.result;
      };

      return this.$http
        .post(`api/custom_login/${this.$route.params.id}/upload`, formData)
        .delegateTo(api_request)
        .then(() => {
          this.$emit("sonVal", true);
          return this.$snackbar.showMessage({
            content: `上传成功`,
            isError: false
          });
        })
        .catch(({ code, message }) => {
          throw `上传失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    getPreLoginStyle() {
      if (
        this.customData &&
        this.customData.content &&
        this.customData.content.PC
      ) {
        let {
          logoDesc,
          showUseInstruction,
          useInstructionTitle,
          useInstruction,
          qrcode,
          showDefaultQrcode,
          tabs,
          otherLinks,
          loginSubmit,
          usernamePlaceholder,
          passwordPlaceholder,
          verifycodePlaceholder,
          noticeDisplay,
          loginStyle,
          footContent,
          loginFrame: {
            loginBgColor,
            loginBgOpcity,
            loginFontColor,
            loginHighLight,
            loginSite
          }
        } = this.customData.content.PC;
        if (loginSite === "center") {
          this.centerDisplay = "dynamic";
        } else {
          this.noticeDisplay = noticeDisplay ? noticeDisplay : "dynamic";
        }

        this.loginStyle = loginStyle;
        this.loginSite = loginSite ? loginSite : "right";
        this.logoDesc = logoDesc;
        this.showUseInstruction = showUseInstruction;
        this.useInstructionTitle = useInstructionTitle;
        this.useInstruction = useInstruction;
        this.qrcode = qrcode == null || qrcode === "不启用" ? "不启用" : qrcode;
        this.showDefaultQrcode = showDefaultQrcode;
        this.loginSubmit = loginSubmit;
        this.usernamePlaceholder = usernamePlaceholder;
        this.passwordPlaceholder = passwordPlaceholder;
        this.verifycodePlaceholder = verifycodePlaceholder;
        this.firstLogin =
          tabs && tabs[0] && tabs[0].id ? tabs[0].id : this.firstLogins[0];
        this.secondLogin =
          tabs && tabs[1] && tabs[1].id ? tabs[1].id : this.secondLogins[0];
        this.thirdLogin =
          tabs && tabs[2] && tabs[2].id ? tabs[2].id : this.thirdLogins[0];

        // 其他登录方式
        this.firstLink =
          otherLinks && otherLinks[0] && otherLinks[0].id
            ? otherLinks[0].id
            : this.firstLinks[0];
        this.secondLink =
          otherLinks && otherLinks[1] && otherLinks[1].id
            ? otherLinks[1].id
            : this.secondLinks[0];
        this.thirdLink =
          otherLinks && otherLinks[2] && otherLinks[2].id
            ? otherLinks[2].id
            : this.thirdLinks[0];
        this.forthLink =
          otherLinks && otherLinks[3] && otherLinks[3].id
            ? otherLinks[3].id
            : this.forthLinks[0];
        this.loginBgOpcity = loginBgOpcity ? loginBgOpcity : 0.6;
        if (loginBgColor && loginBgColor.length === 4) {
          this.loginBgColor = loginBgColor.concat(loginBgColor.substr(1, 3));
        } else {
          this.loginBgColor = loginBgColor;
        }

        if (loginFontColor && loginFontColor.length === 4) {
          this.loginFontColor = loginFontColor.concat(
            loginFontColor.substr(1, 3)
          );
        } else {
          this.loginFontColor = loginFontColor;
        }

        if (loginHighLight && loginHighLight.length === 4) {
          this.loginHighLight = loginHighLight.concat(
            loginHighLight.substr(1, 3)
          );
        } else {
          this.loginHighLight = loginHighLight;
        }

        this.footContent = footContent;
      }
    },
    modifyPreLoginStyle() {
      if (this.$refs.form.validate()) {
        let links = [
          this.firstLink,
          this.secondLink,
          this.thirdLink,
          this.forthLink
        ];
        let tabss = [this.firstLogin, this.secondLogin, this.thirdLogin];
        let allLoginMehods = this.allSources.concat(this.allMethods);
        let tabs = this.getArrEqual(tabss, allLoginMehods);
        let otherLinks = this.getArrEqual(links, allLoginMehods);

        let loginFrame;
        if (this.device_type === "PC") {
          loginFrame = {
            loginBgColor: this.loginBgColor,
            loginBgOpcity: this.loginBgOpcity ? this.loginBgOpcity : 0.6,
            loginFontColor: this.loginFontColor,
            loginHighLight: this.loginHighLight,
            loginSite: this.loginSite
          };
        } else {
          loginFrame = {
            loginBgColor: this.loginBgColor,
            loginBgOpcity: this.loginBgOpcity,
            loginFontColor: this.loginFontColor,
            loginHighLight: this.loginHighLight
          };
        }

        let content = {
          tabs,
          otherLinks,
          qrcode: this.qrcode === "不启用" ? null : this.qrcode,
          showDefaultQrcode: this.showDefaultQrcode,
          showUseInstruction: this.showUseInstruction,
          useInstruction: this.useInstruction,
          usernamePlaceholder: this.usernamePlaceholder,
          passwordPlaceholder: this.passwordPlaceholder,
          verifycodePlaceholder: this.verifycodePlaceholder,
          loginSubmit: this.loginSubmit,
          logoDesc: this.logoDesc,
          useInstructionTitle: this.useInstructionTitle,
          noticeDisplay:
            this.loginSite !== "center"
              ? this.noticeDisplay
              : this.centerDisplay,
          loginStyle: this.loginStyle,
          footContent: this.footContent,
          loginFrame
        };

        let payload = {
          device_type: this.device_type,
          custom_login_id: Number(this.$route.params.id),
          content
        };

        return this.$http
          .post(
            `api/custom_login/${this.$route.params.id}/modify_content`,
            payload
          )
          .delegateTo(api_request)
          .then(() => {
            this.$emit("sonVal", false);
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `上传失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    getArrEqual(arr2, arr1) {
      let newArr = [];
      for (let i = 0; i < arr2.length; i++) {
        for (let j = 0; j < arr1.length; j++) {
          if (arr1[j].external_id === arr2[i]) {
            let curItem = {
              id: arr1[j].external_id,
              name: arr1[j].name
            };
            newArr.push(curItem);
          }
        }
      }
      return newArr;
    },
    fetchSource() {
      return this.$http
        .get(`api/source/list?offset=0&limit=1000`)
        .delegateTo(api_request)
        .then(res => {
          this.allSources = res.sources.filter(item => item.enabled);
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchMethods() {
      return this.$http
        .get(`api/method/list?offset=0&limit=1000`)
        .delegateTo(api_request)
        .then(res => {
          this.allMethods = res.sources.filter(item => item.enabled);
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    dealLoginMehods() {
      let allLoginMehods = this.allSources.concat(this.allMethods);
      this.firstLogins = allLoginMehods.filter(
        item =>
          item.type === "SMS" ||
          item.type === "PASSWORD" ||
          item.type === "FNU_LDAP" ||
          item.type === "LDAP"
      );

      this.firstLogins.unshift("不启用");
    },
    dealQrcode() {
      let allLoginMehods = this.allSources.concat(this.allMethods);
      this.qrcodes = allLoginMehods.filter(
        item =>
          item.type === "WECHAT" ||
          item.type === "WECHAT_ENTERPRISE" ||
          item.type === "WECHAT_RZKC" ||
          item.type === "DING_TALK"
      );
      this.qrcodes.unshift("不启用");
    },
    dealLinkMehods() {
      let allLoginMehods = this.allSources.concat(this.allMethods);
      this.firstLinks = allLoginMehods.filter(
        item =>
          item.type === "CAS" ||
          item.type === "CAS_REST_API" ||
          item.type === "OAUTH2" ||
          item.type === "LARK" ||
          item.type === "HTTP_BASIC"
      );
      this.firstLinks.unshift("不启用");
    }
  },
  watch: {
    customData() {
      this.getPreLoginStyle();
    },
    firstLogins() {
      return (this.secondLogins = this.firstLogins.filter(
        item => item.external_id !== this.firstLogin
      ));
    },
    secondLogins() {
      return (this.thirdLogins = this.secondLogins.filter(
        item => item.external_id !== this.secondLogin
      ));
    },
    firstLinks() {
      return (this.secondLinks = this.firstLinks.filter(
        item => item.external_id !== this.firstLink
      ));
    },
    secondLinks() {
      return (this.thirdLinks = this.secondLinks.filter(
        item => item.external_id !== this.secondLink
      ));
    },
    thirdLinks() {
      return (this.forthLinks = this.thirdLinks.filter(
        item => item.external_id !== this.thirdLink
      ));
    }
  },
  computed: {
    loginBgStyle() {
      const { loginBgColor, loginBgMenu } = this;
      return {
        backgroundColor: loginBgColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: loginBgMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    loginFontStyle() {
      const { loginFontColor, loginFontMenu } = this;
      return {
        backgroundColor: loginFontColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: loginFontMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    loginHighLightStyle() {
      const { loginHighLight, HighLightMenu } = this;
      return {
        backgroundColor: loginHighLight,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: HighLightMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    navigatorBgStyle() {
      const { navigatorBgColor, navigatorBgMenu } = this;
      return {
        backgroundColor: navigatorBgColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: navigatorBgMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    applicationBgStyle() {
      const { applicationBgColor, applicationBgMenu } = this;
      return {
        backgroundColor: applicationBgColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: applicationBgMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  font-size: 14px;
  color: rgb(44, 62, 80);
}
/deep/ .v-select__selections {
  font-size: 14px;
}

/deep/ .v-text-field__slot {
  font-size: 14px;
}

/deep/ .v-input__slot {
  padding-left: 5px;
}

.slider-item {
  /deep/ .v-input__slot {
    padding-left: 0px;
  }
}

.hint-style {
  padding-left: 30%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
